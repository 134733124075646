/*********** Routes for applications **************/
import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import AppRoute from './AppRoute';
import { Auth } from '../auth';
import { publicLayout, privateLayout } from '../components/Layouts';
import Login from '../containers/login';
import Dashboard from '../containers/dashboard';
import ProjectNotifications from '../containers/ProjectNotifications';
import ProjectView from '../containers/project/projectDetail';
import ProjectList from '../containers/project/projectListing';
import AcceptInvite from '../containers/project/acceptInvite';
import DeclineInvite from '../containers/project/declineInvite';
import ProfileUpdate from '../containers/profileUpdate';
import WorldSavers from '../components/worldsaver/WorldSavers';
import memberDetail from '../containers/member/memberDetail';
import memberListing from '../containers/member/memberListing';
import blogListing from '../containers/blogs';
import blogDetail from '../containers/blogs/blogdetail';
import memberEdit from '../containers/member/memberEdit';
import youtube from '../containers/youtube';
import memberMediaPage from '../containers/member/memberMediaPage';
import TabsProvider from '../components/provider/Porvider';
import { socketConnection } from '../utilities/socket';
import Terms from '../components/content/Terms';
import Privacy from '../components/content/Privacy';
import AboutPage from '../components/content/About';
import YoutubePlayer from '../components/YoutubePlayer';
import Event from '../containers/Events/event';
import EventReview from '../containers/Events/EventReview';
import Booking from '../containers/Events/Booking';
import MyJorney from '../containers/project/MyJorney';
import JourneyEdit from '../containers/project/JourneyEdit';
import AddEditEvent from '../containers/Events/AddEditEvent';
import ViewPost from '../components/Posts/ViewPost';
import BlockedUser from '../components/BlockedUsers/BlockedUser';
const Routers = (store) => {
  socketConnection.on('receive-message', (data) => {
    //console.log("socketConnection aapp", data)
    let element = document.getElementById('ChatNotification');
    if (element) element.click();
    let el2 = document.getElementById('getFriendListJs');
    if (el2) el2.click();
  });

  return (
    <TabsProvider>
      <Switch>
        <AppRoute
          exact={true}
          path="/"
          component={Login}
          requireAuth={Auth}
          layout={publicLayout}
          store={store}
          type="public"
        />
        <AppRoute
          exact={true}
          path="/signup"
          component={Login}
          requireAuth={Auth}
          layout={publicLayout}
          store={store}
          type="public"
        />
        <AppRoute
          exact={true}
          path="/dashboard/signup"
          component={Login}
          requireAuth={Auth}
          layout={publicLayout}
          store={store}
          type="public"
        />
        <AppRoute
          exact={true}
          path="/terms"
          component={Terms}
          requireAuth={Auth}
          layout={publicLayout}
          store={store}
          type="public"
        />
        <AppRoute
          exact={true}
          path="/about"
          component={AboutPage}
          requireAuth={Auth}
          layout={publicLayout}
          store={store}
          type="public"
        />{' '}
        <AppRoute
          exact={true}
          path="/blockeduser"
          component={BlockedUser}
          requireAuth={Auth}
          layout={privateLayout}
          store={store}
          type="public"
        />
        <AppRoute
          exact={true}
          path="/privacy"
          component={Privacy}
          requireAuth={Auth}
          layout={publicLayout}
          store={store}
          type="public"
        />
        <AppRoute
          exact={true}
          path="/signin"
          component={Login}
          requireAuth={Auth}
          layout={publicLayout}
          store={store}
          type="public"
        />
        <AppRoute
          exact={true}
          path="/memberedit"
          component={memberEdit}
          requireAuth={Auth}
          layout={privateLayout}
          store={store}
          type="public"
        />
        <AppRoute
          exact={true}
          path="/memberlisting"
          component={memberListing}
          requireAuth={Auth}
          layout={privateLayout}
          store={store}
          type="public"
        />
        <AppRoute
          exact={true}
          path="/membermedia/:userId/:type"
          component={memberMediaPage}
          requireAuth={Auth}
          layout={privateLayout}
          store={store}
          type="public"
        />
        <AppRoute
          exact={true}
          path="/blogdetail/:id"
          component={blogDetail}
          requireAuth={Auth}
          layout={privateLayout}
          store={store}
          type="public"
        />
        <AppRoute
          exact={true}
          path="/journeyList"
          component={ProjectList}
          requireAuth={Auth}
          layout={privateLayout}
          store={store}
          type="public"
        />
        <AppRoute
          exact={true}
          path="/post/:id"
          component={ViewPost}
          requireAuth={Auth}
          layout={privateLayout}
          store={store}
          type="public"
        />
        <AppRoute
          exact={true}
          path="/myjourney"
          component={MyJorney}
          requireAuth={Auth}
          layout={privateLayout}
          store={store}
          type="public"
        />
        <AppRoute
          exact={true}
          path="/myjourney/:id"
          component={JourneyEdit}
          requireAuth={Auth}
          layout={privateLayout}
          store={store}
          type="public"
        />
        <AppRoute
          exact={true}
          path="/worldsavers"
          component={WorldSavers}
          requireAuth={Auth}
          layout={privateLayout}
          store={store}
          type="public"
        />
        <AppRoute
          exact={true}
          path="/profile"
          component={ProfileUpdate}
          requireAuth={Auth}
          layout={privateLayout}
          store={store}
          type="public"
        />
        <AppRoute
          exact={true}
          path="/showarchives"
          component={youtube}
          requireAuth={Auth}
          layout={privateLayout}
          store={store}
          type="public"
        />
        <AppRoute
          exact={true}
          path="/bloglisting"
          component={blogListing}
          requireAuth={Auth}
          layout={privateLayout}
          store={store}
          type="public"
        />
        <AppRoute
          exact={true}
          path="/bloglisting/:cat_id"
          component={blogListing}
          requireAuth={Auth}
          layout={privateLayout}
          store={store}
          type="public"
        />
        <AppRoute
          exact={true}
          path="/journey/:id"
          component={ProjectView}
          requireAuth={Auth}
          layout={privateLayout}
          store={store}
          type="public"
          //something went wrong
        />
        <AppRoute
          exact={true}
          path="/mybooking/:id"
          component={Booking}
          requireAuth={Auth}
          layout={privateLayout}
          store={store}
          type="public"
          //something went wrong
        />
        <AppRoute
          exact={true}
          path="/journey/:id/:postId"
          component={ProjectView}
          requireAuth={Auth}
          layout={privateLayout}
          store={store}
          type="public"
          //something went wrong
        />
        <AppRoute
          exact={true}
          path="/acceptInvitaion/:inviteCode"
          component={AcceptInvite}
          requireAuth={Auth}
          layout={privateLayout}
          store={store}
          //something went wrong
        />
        <AppRoute
          exact={true}
          path="/declineInvitaion/:inviteCode"
          component={DeclineInvite}
          requireAuth={Auth}
          layout={privateLayout}
          store={store}

          //something went wrong
        />
        <AppRoute
          exact={true}
          path="/dashboard"
          component={Dashboard}
          requireAuth={Auth}
          layout={privateLayout}
          store={store}
          type="public"
        />
        <AppRoute
          exact={true}
          path="/events"
          component={Event}
          requireAuth={Auth}
          layout={privateLayout}
          store={store}
          type="public"
        />
        <AppRoute
          exact={true}
          path="/addevent"
          component={AddEditEvent}
          requireAuth={Auth}
          layout={privateLayout}
          store={store}
          type="public"
        />
        <AppRoute
          exact={true}
          path="/editevent/:id"
          component={AddEditEvent}
          requireAuth={Auth}
          layout={privateLayout}
          store={store}
          type="public"
        />
        <AppRoute
          exact={true}
          path="/event/:title"
          component={EventReview}
          requireAuth={Auth}
          layout={privateLayout}
          store={store}
          type="public"
        />
        <AppRoute
          exact={true}
          path="/notifications"
          component={ProjectNotifications}
          requireAuth={Auth}
          layout={privateLayout}
          store={store}
        />
        <AppRoute
          exact={true}
          path="/:id/:mode?"
          component={memberDetail}
          requireAuth={Auth}
          layout={privateLayout}
          store={store}
          type="public"
        />
        <Redirect to="/" />
      </Switch>

      <YoutubePlayer />
    </TabsProvider>
  );
};

export default Routers;
